import { Method } from 'axios';

export const API_METHOD_POST: Method = 'POST';
export const API_METHOD_GET: Method = 'GET';
export const API_METHOD_PUT: Method = 'PUT';
export const API_METHOD_DELETE: Method = 'DELETE';

const BASE_API_URL =
  process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000/api';

const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION || '/v1';

export const API_URL = `${BASE_API_URL}${API_VERSION}`;

export const API_AUTH_LOGIN_URL = `${API_URL}/auth/login`;
export const API_AUTH_LOGOUT_URL = `${API_URL}/auth/logout`;

export const API_AUTH_REFRESH_TOKEN_URL = `${API_URL}/auth/refresh-token`;

export const API_REGISTER_URL = `${API_URL}/onboarding-team-members`;

//PROFILE APIS
export const API_PROFILE_DETAILS_UPDATE = `${API_URL}/profile`;
export const API_PROFILE_PASSWORD_UPDATE = `${API_URL}/profile/change-password`

// COMPANY APIS
export const API_COMPANY_SOCIAL_LINK_UPDATE_URL = `${API_URL}/company/settings/social-links-update`;

export const API_COMPANY_TAX_RATES = `${API_URL}/company/tax-rates`;

export const API_COMPANY_SETTINGS_TAX_DETAILS_UPDATE = `${API_URL}/company/settings/tax-details-update`;

export const API_COMPANY_SETTINGS_UPDATE = `${API_URL}/company/settings/update`;

export const API_COMPANY_SETTINGS = `${API_URL}/company/settings`;

export const API_COMPANY_DELETE_LOGO = `${API_URL}/company/settings/logo-delete`;

// COMMON APIS
export const API_COMMON_PUBLIC_KEY_URL = `${API_URL}/common/public_key`;

export const API_COMMON_UPLOAD_FILE = `${API_URL}/common/upload-file`;

export const API_COMMON_TIMEZONES = `${API_URL}/common/timezones`;

export const API_COMMON_DATE_FORMATS = `${API_URL}/common/date-formats`;

export const API_COMMON_TIME_FORMATS = `${API_URL}/common/time-formats`;

export const API_COMMON_DAY_OF_WEEK = `${API_URL}/common/days-of-weeks`;

export const API_COMMON_COUNTRIES = `${API_URL}/common/countries`;

export const API_COMMON_TEAMMEMBER_STATUS = `${API_URL}/common/status/team-member-status`;

export const API_COMMON_QUOTES_STATUS = `${API_URL}/common/status/quote-status`;

export const API_CUSTOMER_TITLES = `${API_URL}/common/status/customer-titles`;

export const API_CALCULATE_FINANCES = `${API_URL}/common/calculate-finances`;

// TEAM APIS
export const API_TEAM_MEMBERS = `${API_URL}/team-members`;

export const API_GET_TEAM_MEMBER_BY_ID = `${API_URL}/team-members`;

export const API_ADD_TEAM_MEMBER = `${API_URL}/team-members`;

export const API_COMMON_CUSTOMER_STATUS = `${API_URL}/common/status/customer-status`;

// CUSTOMER APIS
export const API_CUSTOMERS = `${API_URL}/customers`;

export const API_CUSTOMER_SITES = (customerUuid: string) =>
  `${API_CUSTOMERS}/${customerUuid}/sites`;

export const API_CUSTOMER_SITES_UPDATE = (
  customerUuid: string,
  siteUuid: string,
) => `${API_CUSTOMERS}/${customerUuid}/sites/${siteUuid}`;

export const API_CUSTOMER_NOTES = (customerUuid: string) =>
  `${API_CUSTOMERS}/${customerUuid}/notes`;

export const API_CUSTOMER_NOTES_UPDATE = (
  customerUuid: string,
  noteUuid: string,
) => `${API_CUSTOMERS}/${customerUuid}/notes/${noteUuid}`;

export const API_CUSTOMERS_EXPORT = `${API_URL}/customers/export`;

export const API_UPDATE_TEAM_MEMBER = `${API_URL}/team-members`;

export const DELETE_IMAGE = `${API_URL}`;

export const API_PRODUCTS = `${API_URL}/products`;

export const API_PRODUCTS_IMPORT = `${API_URL}/products/import`;

export const API_PRODUCTS_EXPORT = `${API_URL}/products/export`;

// QUOTE APIS
export const API_GET_QUOTES = `${API_URL}/quotes`;

export const API_CREAT_QUOTE = `${API_URL}/quotes`;

export const API_UPDATE_QUOTE = `${API_URL}/quotes`;

// JOBS APIS
export const API_JOBS = `${API_URL}/jobs`;
export const API_GET_JOB_STATUS = `${API_URL}/common/status/job-status`;
export const API_GET_JOB_TYPE = `${API_URL}/common/status/job-type`;
export const API_GET_JOB_RECURRING_TYPE = `${API_URL}/common/status/job-recurring-type`;

// Schedule Jobs APIS
export const API_SCHEDULE_JOBS = `${API_URL}/jobs/job-schedules`;

// EXPENSES APIS
export const API_ADD_EXPENSES = `${API_URL}/expenses`;
export const API_EXPENSES = `${API_URL}/expenses`;

// timesheet APIS

export const API_TIMESHEETS = `${API_URL}/timesheets`;
export const API_TIMESHEET_CATEGORY = `${API_URL}/common/status/timesheet-category`;

// ENQUIRY APIS
export const API_ENQUIRY = `${API_URL}/enquiries`;

export const API_GET_ENQUIRY_STATUS = `${API_URL}/common/status/enquiry-status`;

// INVOICE APIs
export const API_INVOICE = `${API_URL}/invoices`;

export const API_INVOICE_COMMON = `${API_URL}`;

export const API_FORGOT_PASSWORD = `${API_URL}/auth/forgot-password`;

export const API_RESET_PASSWORD = `${API_URL}/auth/reset-password`;

export const API_EXPORT = `${API_URL}/common/exports`;
export const API_ACCOUNT_BILLING = `${API_URL}/subscription`;

export const API_SUBSCRIPTION_GET_DETAIL = `${API_URL}/subscription/get-details`;

export const API_SUBSCRIPTION_CHECKOUT = `${API_URL}/subscription/create`;

export const API_PUSH_NOTIFICATION = `${API_URL}/push-notification`;
export const API_PUSH_NOTIFICATION_COUNT = `${API_URL}/push-notification/count`;
export const API_PUSH_NOTIFICATION_MARK = `${API_URL}/push-notification/mark-as-read`;
