// utils/deviceId.ts
import { v4 as uuid_v4 } from 'uuid';

export const getDeviceId = (): string => {
  if (typeof window === 'undefined') {
    // Return a fallback value or handle accordingly during SSR
    return 'ssr-device-id';
  }

  const key = 'device-id-zyn-off';
  let deviceId = localStorage.getItem(key);

  if (!deviceId) {
    deviceId = uuid_v4();
    localStorage.setItem(key, deviceId);
  }

  return deviceId;
};
