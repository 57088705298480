import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_METHOD_POST, API_JOBS } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';

export const UploadImageToJob = async (uuid: string, payload: object) => {
  const method: Method = API_METHOD_POST;
  const url = API_JOBS + `/${uuid}/photos`;
  const data = payload;

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error;
  }
};
