import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  Expense,
  ExpensesMetricsData,
  Pagination,
  SingleExpenseData,
} from '@/helpers/types';
import { AddExpense } from '@/api/expenses/addExpense';
import { toast } from 'react-toastify';
import { GetExpensesData } from '@/api/expenses/getExpenses';
import { SearchExpensesData } from '@/api/expenses/searchExpenses';
import { DeleteExpenseData } from '@/api/expenses/deleteExpense';
import { GetExpenseById } from '@/api/expenses/getExpenseById';
import { UpdateExpense } from '@/api/expenses/updateExpense';
import { GetExpensesMetrics } from '@/api/expenses/getExpensesMetrics';

// Define the type for the API response
interface ExpensesResponse {
  data: Expense[];
  pagination: Pagination;
}

interface ExpenseMetricsResponse {
  data: ExpensesMetricsData;
}

type ExpenseMetricsError = string | SerializedError;

interface ExpenseByIdResponse {
  data: SingleExpenseData;
}

// Define the type for the thunk return value
type FetchExpensesResponse = {
  data: Expense[];
  pagination: Pagination;
};

type FetchExpensesError = string | SerializedError;
type SearchExpensesArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
  startDate?: string | null;
  endDate?: string | null;
};
type DeleteExpensesArgs = string;
type SingleExpensesArgs = string;
type updateExpenseArgs = {
  expenseUuid: string;
  expenseData: Expense;
};
/*type MetricsExpensesResponse = {
  status: string; // e.g., "SUCCESS"
  data: {
    total_expense: number; // Total expense value
  };
};*/

export const addExpense = createAsyncThunk<
  Expense,
  Expense,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'expenses/addExpenses',
  async (newExpense: Expense, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddExpense(newExpense); // Function to send a POST request
      toast.success(response.message);
      dispatch(fetchExpensesMetrics());
      dispatch(fetchExpenses());
      return response.data; // Return the created customer
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const fetchExpenses = createAsyncThunk<
  FetchExpensesResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchExpensesError; // Type for rejected value
  }
>('expenses/fetchExpenses', async (_, { rejectWithValue }) => {
  try {
    const response = await GetExpensesData();
    const data: ExpensesResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchExpenses = createAsyncThunk<
  FetchExpensesResponse,
  SearchExpensesArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchExpensesError;
  }
>(
  'expenses/searchExpenses',
  async (
    { searchTerm, pageSize, pagination, startDate, endDate },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchExpensesData(
        searchTerm,
        pageSize,
        pagination,
        startDate,
        endDate,
      ); // Pass both parameters
      const data: ExpensesResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteExpense = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteExpensesArgs, // Argument type of the payload creator
  {
    rejectValue: FetchExpensesError; // Type for rejected value
  }
>(
  'expenses/deleteExpenses',
  async (expenseUuId: string, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the team member
      await DeleteExpenseData(expenseUuId);
      toast.success('Expense deleted successfully!');
      // After successful deletion, dispatch fetchTeamMembers to refresh the list
      dispatch(fetchExpensesMetrics());
      dispatch(fetchExpenses());
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchExpenseById = createAsyncThunk<
  ExpenseByIdResponse, // The expected return type
  SingleExpensesArgs, // The expected argument type (UUID)
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>(
  'expenses/fetchExpenseById',
  async (expenseUuid: string, { rejectWithValue }) => {
    try {
      const response = await GetExpenseById(expenseUuid);
      return response.data; // Return the single customer data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const updateExpense = createAsyncThunk<
  Expense,
  updateExpenseArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'expenses/updateExpenses',
  async ({ expenseUuid, expenseData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await UpdateExpense(expenseUuid, expenseData); // Function to send a POST request
      toast.success(response.message);
      dispatch(fetchExpensesMetrics());
      dispatch(fetchExpenses());
      return response; // Return the created customer site
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const fetchExpensesMetrics = createAsyncThunk<
  ExpenseMetricsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: ExpenseMetricsError; // Type for rejected value
  }
>('expenses/fetchExpensesMetrics', async (_, { rejectWithValue }) => {
  try {
    const response = await GetExpensesMetrics();
    const data: ExpenseMetricsResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
